import regeneratorRuntime from "/vercel/path0/projects/merchant/node_modules/next/node_modules/regenerator-runtime/runtime.js";
import { useAppBridge } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import useAuthAxios from '@/hooks/use-auth-axios';
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
/**
 * setIsBillingActivated に falseを渡すと課金の承認ページへリダイレクトする
 */ var useBillingCheck = function(refetch) {
    var app = useAppBridge();
    var authAxios = useAuthAxios().authAxios;
    var redirect = Redirect.create(app);
    var router = useRouter();
    var ref1 = useState(null), isBillingActivated = ref1[0], setIsBillingActivated = ref1[1];
    useEffect(function() {
        var main = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
            var ref, _data, isAlreadyActivated, confirmationUrl;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        _ctx.next = 2;
                        return authAxios.get('/subscriptions/confirmation_url', {
                            params: {
                                plan: 'STANDARD'
                            }
                        });
                    case 2:
                        ref = _ctx.sent;
                        _data = ref.data;
                        isAlreadyActivated = _data.isAlreadyActivated;
                        confirmationUrl = _data.confirmationUrl;
                        if (!isAlreadyActivated) {
                            _ctx.next = 10;
                            break;
                        }
                        _ctx.next = 9;
                        return refetch();
                    case 9:
                        return _ctx.abrupt("return");
                    case 10:
                        try {
                            redirect.dispatch(Redirect.Action.REMOTE, confirmationUrl);
                        } catch (e) {
                            router.replace({
                                pathname: confirmationUrl
                            });
                        }
                    case 11:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        }));
        if (isBillingActivated === false) {
            main();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isBillingActivated
    ]);
    return {
        setIsBillingActivated: setIsBillingActivated
    };
};
export default useBillingCheck;
