import { useAppBridge } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
/**
 * app-bridge の redirectオブジェクト
 */ var useRedirect = function() {
    var app = useAppBridge();
    var redirect = Redirect.create(app);
    return {
        redirect: redirect
    };
};
export default useRedirect;
